import { Accordion, Form, Button, Modal, Toast, ToastContainer } from "react-bootstrap";
import { Building, Download, PencilSquare, Person, PersonFill, PersonFillAdd, QuestionCircle, Rocket } from "react-bootstrap-icons";
import Axios from "axios";
import { API_URL, USER_TYPES, UserTypeName } from "../../context/Types";
import { createRef, useContext, useEffect, useState } from "react";
import SessionContext from "../../context/SessionContext";
import { loadDrivers } from "./Runs";
import { Typeahead } from "react-bootstrap-typeahead";
import MachineIdForm from "../MachineIdForm";

export default function Admin() {
    const { session } = useContext(SessionContext);
    const driver_selected = createRef();
    const company_evaluator = createRef();
    const company_driver = createRef();
    const launchBtn = createRef();
    const cancelBtn = createRef();
    const [drivers, setDrivers] = useState([]);
    const [companies, setCompanies] = useState([]); //country, id, name
    const [appLaunched, setAppLaunched] = useState(false);
    const [selectedDriverName, setSelectedDriverName] = useState();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showLaunchToast, setShowLaunchToast] = useState(false);



    useEffect(() => {
        loadDrivers(session.token, setDrivers);
        loadCompanies(session.token, setCompanies);
    }, [session.token])


    function loadCompanies(token, setCompaniesFunction) {
        const queryParams = {};
        queryParams.token = token;
        Axios.get(API_URL + "/companies", { params: queryParams }).then(response => {
            if (response.data.success) {
                // console.log(response.data.companies);
                setCompaniesFunction(response.data.companies);
            }
        });
    }

    function createDriver(e) {
        e.preventDefault();
        const queryParams = {};
        queryParams.first_name = e.target.first_name.value;
        queryParams.last_name = e.target.last_name.value;
        queryParams.rut = e.target.rut.value;
        queryParams.email = e.target.email.value;
        queryParams.password = e.target.password.value;
        queryParams.token = session.token;

        var companyName;

        if (session.user.type == USER_TYPES.ADMIN) {
            companyName = company_driver.current.state.selected[0];
        } else {
            companyName = session.user.company_name;
        }
        queryParams.company_id = companies.find(item => item.name == companyName).id;

        Axios.post(API_URL + "/driver/create", queryParams).then(response => {
            if (response.data.success) {
                alert("Conductor creado 👍");
                window.location.reload();
            } else {
                alert("Error mientras se creaba un conductor.")
            }
        });
    }
    function createEvaluator(e) {
        e.preventDefault();
        const queryParams = {};
        queryParams.first_name = e.target.first_name.value;
        queryParams.last_name = e.target.last_name.value;
        queryParams.rut = e.target.rut.value;
        queryParams.email = e.target.email.value;
        queryParams.password = e.target.password.value;
        queryParams.token = session.token;
        var companyName = company_evaluator.current.state.selected[0];
        queryParams.company_id = companies.find(item => item.name == companyName).id;

        Axios.post(API_URL + "/evaluator/create", queryParams).then(response => {
            if (response.data.success) {
                alert(UserTypeName(USER_TYPES.EVALUATOR) + "creado 👍");
                window.location.reload();
            } else {
                alert("Error mientras se creaba un perfil de manager.")
            }
        });
    }

    function createCompany(e) {
        e.preventDefault();
        const queryParams = {};
        queryParams.name = e.target.name.value;
        queryParams.token = session.token;
        Axios.post(API_URL + "/company/create", queryParams).then(response => {
            if (response.data.success) {
                alert("Empresa creada 👍");
                window.location.reload();
            } else {
                alert("Error mientras se creaba una empresa.")
            }
        });
    };


    function launchApp(e) {
        e.preventDefault();

        setSelectedDriverName(String(driver_selected.current.state.selected[0].label).split("- ")[1]);

        const daemonData = {
            evaluator_token: session.token,
            driver_name: String(driver_selected.current.state.selected[0].label).split("- ")[1],
            driver_id: driver_selected.current.state.selected[0].id
        }

        Axios.post("http://127.0.0.1:3005/data", daemonData).then(response => {
            setAppLaunched(true);
            setShowLaunchToast(true);
        }).catch(err => {
            setShowErrorModal(true);
            setAppLaunched(false);
        });
    }

    function clearData(e) {
        e.preventDefault();
        Axios.get("http://127.0.0.1:3005/clear").then(res => {
            setAppLaunched(false);
        }).catch(err => {
            setAppLaunched(false);
            setShowErrorModal(true);
        });
    }

    function DaemonErrorModal() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showErrorModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ⚠️ Daemon no inicializado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Hubo un problema inicializando la simulación</h4>
                    <p>
                        Por favor, verifica tu conexión a internet y que el servicio daemon esté abierto.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setShowErrorModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    function DaemonHelpModal() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showHelpModal}
                onHide={() => setShowHelpModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ¿Qué es el daemon?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Se necesita el archivo <b>"daemon.exe"</b>, este debe estar abierto si quieres
                        lanzar el simulador y enviar la información de un conductor.
                    </p>
                    <img src="img/daemon_screenshot.png" />

                </Modal.Body>
                <Modal.Footer>
                    <Button href="http://smartdrivr.sciremotion.com/daemon.exe" className="btn-secondary"><Download /> Descargar archivo</Button>
                    <Button onClick={() => setShowHelpModal(false)}>Entiendo</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function LaunchNotification() {
        return (
            <ToastContainer position="bottom-end">
                <Toast onClose={() => setShowLaunchToast(false)} show={showLaunchToast} delay={6000} autohide >
                    <Toast.Header>
                        <img
                            src="logo_smart_drivr.png"
                            height='10px'
                        />
                        <strong className="me-auto">Simulador</strong>
                        <small>Ahora</small>
                    </Toast.Header>
                    <Toast.Body>Has iniciado el simulador con el usuario <b>{selectedDriverName}</b></Toast.Body>
                </Toast>
            </ToastContainer>
        );
    }


    function CreateDriverForm() {
        return (
            <Accordion.Item eventKey="1">
                <Accordion.Header><PersonFillAdd style={{ marginRight: '6px' }} />Crear perfil de conductor</Accordion.Header>
                <Accordion.Body>
                    {session.user.company_name && <p>Aquí podrás crear un nuevo perfil de conductor para la empresa <b>{session.user.company_name}</b></p>}
                    <Form onSubmit={createDriver}>
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control aria-label="Nombre" required id="first_name" />
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control aria-label="Apellido" required id="last_name" />
                        <Form.Label>R.U.T / Identificador</Form.Label>
                        <Form.Control aria-label="R.U.T o Identificador" placeholder="Sin puntos ni dígito verificador" type="number" required id="rut" />
                        <Form.Label>Email</Form.Label>
                        <Form.Control aria-label="Email" placeholder="cuenta@email.com" required id="email" />
                        {session.user.type == USER_TYPES.ADMIN &&
                            <> < Form.Label>Empresa</Form.Label>
                                <Typeahead
                                    id='companiesList'
                                    options={companies.map(item => item.name)}
                                    placeholder="Selecciona la empresa..."
                                    emptyLabel="No se encontró ninguna empresa con este nombre."
                                    ref={company_driver}
                                    required
                                />
                            </>}
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control aria-label="Contraseña" required id="password" />
                        <br />
                        <Button type="submit" id="btn" >Crear perfil <PencilSquare /> </Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item >
        );
    }
    function CreateEvaluatorForm() {
        return (
            <Accordion.Item eventKey="0">
                <Accordion.Header><PencilSquare style={{ marginRight: '6px' }} />Crear perfil de {UserTypeName(USER_TYPES.EVALUATOR).toLowerCase()}</Accordion.Header>
                <Accordion.Body>
                    <Form onSubmit={createEvaluator}>
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control aria-label="Nombre" required id="first_name" />
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control aria-label="Apellido" required id="last_name" />
                        <Form.Label>R.U.T / Identificador</Form.Label>
                        <Form.Control aria-label="R.U.T o Identificador" placeholder="Sin puntos ni dígito verificador" type="number" required id="rut" />
                        <Form.Label>Email</Form.Label>
                        <Form.Control aria-label="Email" placeholder="cuenta@email.com" required id="email" />
                        <Form.Label>Empresa</Form.Label>
                        <Typeahead
                            id='companiesList'
                            options={companies.map(item => item.name)}
                            placeholder="Selecciona la empresa..."
                            emptyLabel="No se encontró ninguna empresa con este nombre."
                            ref={company_evaluator}
                            required
                        />
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control aria-label="Contraseña" required id="password" />
                        <br />
                        <Button type="submit" id="btn" >Crear perfil <PencilSquare /> </Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    function CreateCompanyForm() {
        return (
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <Building style={{ marginRight: "6px" }} />Crear nueva empresa
                </Accordion.Header>
                <Accordion.Body>
                    <Form onSubmit={createCompany}>
                        <Form.Label>Nombre de la empresa</Form.Label>
                        <Form.Control
                            type="text"
                            id="name"
                        />
                        <br />
                        <Button type="submit" id="btn">
                            Crear empresa <PencilSquare />
                        </Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    function SetMachineIdForm() {
        return (
            <Accordion.Item eventKey="machineId">
                <Accordion.Header>Configurar patente virtual</Accordion.Header>
                <Accordion.Body>
                    <MachineIdForm />
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    function LaunchApp() {
        return (
            <Accordion.Item eventKey="2">
                <Accordion.Header><Rocket style={{ marginRight: '6px' }} />Lanzar simulador</Accordion.Header>
                <Accordion.Body>
                    <p className="mb-1">Elige un conductor cuyo recorrido será registrado en el simulador.</p>
                    <small className="text-muted">Recuerda tener abierto el servicio daemon. <a href="javascript:void(0)" onClick={() => setShowHelpModal(true)}><QuestionCircle /></a></small>
                    <Form onSubmit={launchApp}>
                        <Form.Label>R.U.T / Identificador</Form.Label>
                        <Typeahead
                            id='driver_id'
                            options={drivers}
                            placeholder="Escribe o selecciona el ID de un conductor..."
                            emptyLabel="No se encontró ningún conductor con este ID"
                            ref={driver_selected}
                            disabled={appLaunched}
                        />
                        <br />
                        <div hidden={!appLaunched}>El conductor actual para la simulación es <b>{selectedDriverName}</b><hr /></div>
                        <Button type="submit" disabled={appLaunched} ref={launchBtn} >Lanzar simulador <Rocket /> </Button>
                        <Button className="btn-danger" disabled={!appLaunched} ref={cancelBtn} onClick={clearData}>Terminar o cancelar</Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    return (
        <>
            <h2>Administración</h2>
            <p>En esta sección se podrán crear y modificar perfiles de conductores, registrar un recorrido manualmente, ejecutar la aplicación en realidad virtual, automatizar correos, etc.</p>
            <hr />
            <Accordion defaultActiveKey={0} style={{ maxWidth: 640 }}>
                <CreateDriverForm />
                {session.user.type == USER_TYPES.ADMIN && <CreateEvaluatorForm />}
                {session.user.type == USER_TYPES.ADMIN && <CreateCompanyForm />}
                {session.user.type != USER_TYPES.DRIVER && <SetMachineIdForm />}
                {/* <LaunchApp /> */}
            </Accordion>
            <DaemonErrorModal />
            <DaemonHelpModal />
            <LaunchNotification />
        </>
    );
}