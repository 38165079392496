import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Container, Image, Card, Nav, Navbar, Row, Col, Accordion } from "react-bootstrap";
import { Envelope, HeadsetVr, Lightbulb, PersonCircle, Plus, Whatsapp } from "react-bootstrap-icons";
import { PRODUCT_NAME } from "../../App";
function HomePanel() {

    const PLACEHOLDER_LOGO = "logo_smart_drivr.png";
    const PLACEHOLDER_IMAGE = "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg";
    const SIMULATOR_IMAGES = ["/img/simulador-1.png", "/img/simulador-2.png"];

    function Header() {
        return (
            <Navbar fixed="top" bg="white" expand="lg">
                <Container fluid style={{ maxWidth: '960px' }} >
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Brand><img src={PLACEHOLDER_LOGO} height='45px' /></Navbar.Brand>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-2 my-lg-0 align-items-center"
                            style={{ maxHeight: '80vh' }}
                            navbarScroll
                        >
                            <Nav.Link href="#product_description">Cómo funciona</Nav.Link>
                            <Nav.Link href="/login">
                                <Button className="m-0">Entrar</Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }

    function Faq() {
        return (
            <Container className="mt-5">
                <h2 className="text-center mb-5">Preguntas frecuentes</h2>
                <Accordion id="faq" defaultActiveKey='0' className="m-auto" style={{ maxWidth: '960px' }}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>¿Cuáles son los requerimientos?</Accordion.Header>
                        <Accordion.Body>
                            <b>Requerimientos Mínimos</b>
                            <ul>
                                <li>Procesador: Intel Core i7 6700HQ</li>
                                <li>Memoria RAM: 8 GB</li>
                                <li>Tarjeta gráfica: Nvidia GeForce GTX 1060</li>
                            </ul>
                            <b>Requerimientos Recomendados</b>
                            <ul>
                                <li>Procesador: AMD Ryzen 7 5800H</li>
                                <li>Memoria RAM: 16 GB</li>
                                <li>Tarjeta gráfica: Nvidia GeForce RTX 3060</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>¿Por qué lo necesito?</Accordion.Header>
                        <Accordion.Body>
                            La seguridad en la carretera es una preocupación constante para las empresas de transporte, ya que los
                            accidentes pueden tener consecuencias graves, tanto para los conductores como para los pasajeros. {PRODUCT_NAME} es una solución innovadora que permite mejorar la seguridad en la carretera, gracias a su simulador de conducción
                            en realidad virtual. Con {PRODUCT_NAME}, las empresas pueden evaluar el comportamiento de sus conductores en diferentes
                            entornos, como carretera, ciudad y espacios rurales, y monitorear los resultados a través de un panel de administración.
                            Además, {PRODUCT_NAME} ayuda a los conductores a mejorar su rendimiento y a respetar más las leyes de tránsito. Con {PRODUCT_NAME},
                            las empresas pueden reducir el riesgo de accidentes y mejorar la eficiencia de sus operaciones de transporte. No pierdas la
                            oportunidad de mejorar la seguridad en la carretera con {PRODUCT_NAME}.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>);
    }

    function AboutUs() {
        return (
            <div id="about_us" className="m-auto d-flex max-960 vh-90">
                <div className="m-auto">
                    <h1 className="text-center">Sobre nosotros</h1>
                    <section className="d-flex flex-wrap justified text-muted">
                        <img className="m-auto" src="logo-sciremotion-color.png" alt="Logotipo de Sciremotion" />
                        <div className="mt-3">
                            Somos parte de Sciremotion, un equipo de expertos en seguridad vial. Desarrollamos {PRODUCT_NAME},
                            una herramienta poderosa que permite evaluar mi comportamiento como conductor en diferentes entornos y
                            monitorear los resultados a través de un panel de administración. Nuestra pasión es ayudar a las empresas
                            a reducir el riesgo de accidentes y mejorar la eficiencia del transporte.
                        </div>
                    </section>
                </div>
            </div>
        );
    }

    function Content() {
        return (
            <div style={{ marginTop: '80px' }}>
                <div className="d-flex m-auto max-960 vh-90">
                    <div className="d-flex flex-wrap justify-content-center align-items-center gap-2" >
                        <div className="w-35 h-75 d-none d-md-block position-relative">
                            <img className="w-100 h-100" src={SIMULATOR_IMAGES[0]}
                                style={{ objectFit: 'cover', boxShadow: '15px 15px  #1db5ec' }} />
                            <img width='250px' height='250px' className="rounded-circle position-absolute" src={SIMULATOR_IMAGES[1]}
                                style={{ objectFit: 'cover', left: '-50px', bottom: '-50px', boxShadow: '15px 15px  #1db5ec' }} />
                        </div>

                        <div className="text-start center_mobile p-5 large-50-mobile-100">
                            <h1 >Capacita.
                                <br />
                                Entrena.
                                <br />
                                <b className="text-primary">Evalúa.</b></h1>
                            <p>Te presentamos un simulador avanzado e inmersivo que brinda
                                una gran experiencia de manejo en un entorno controlado.</p>
                            <img width='200px' height='200px' className="d-block d-md-none m-auto rounded-circle mb-3" src={SIMULATOR_IMAGES[Math.floor(Math.random() * SIMULATOR_IMAGES.length)]}
                                style={{ objectFit: 'cover' }} />
                            <Button href="/signup">Me interesa</Button>
                        </div>
                    </div>
                </div>
                <div id="monitorea" className="p-4 d-flex vh-90 w-100 bg-white">
                    <div className="m-auto d-flex flex-wrap justify-content-center align-items-center max-960">
                        <div className="center_mobile large-50-mobile-100">
                            <h1 className="center_mobile">Analiza resultados</h1>
                            <p className="w-100">Puedes acceder a la información entregada por el simulador y realizar evaluaciones con datos concretos.</p>
                            <Button href="#product_description" className="border-light btn-outline-light shadow-none btn-info">¿Cómo funciona?</Button>
                        </div>
                        <img className="floating" src="https://static.vecteezy.com/system/resources/thumbnails/015/735/720/small/charts-and-graph-with-analysis-business-financial-data-empty-clipboard-checklist-check-isolated-online-marketing-business-strategy-concept-3d-illustration-or-3d-render-png.png" width='480px' />
                    </div>
                </div>
                <div id="product_description" className="m-auto d-flex max-960 vh-90">
                    <div className="m-auto">
                        <h1 className="text-center mt-5 mb-5">Simple y efectivo</h1>
                        <img src="img/workcycle-large.png" className="d-none d-md-block w-75 m-auto" />
                        <img src="img/workcycle-small.png" className="d-block d-md-none w-75 m-auto" />
                        <div className="d-flex flex-wrap justified text-muted mt-3">
                            <div>
                                Desarrollado para el entrenamiento y evaluación de conductores a través de la tecnología de Realidad Virtual (VR). Experimenta situaciones de conducción
                                realistas e inmersivas en ambientes urbanos, rurales y de carretera.
                            </div>
                        </div>
                    </div>
                </div >
                <div className="bg-white">
                    <AboutUs />
                </div>
                <hr />
                <Faq />
                <hr />
                <div className="text-center m-auto mt-5 mb-5">
                    <img height='120px' src="https://kataix.umag.cl/sib-aysen/static/hongusto/resources/img/logo_corfo.png" />
                </div>
            </div >
        );
    }

    return (
        <>
            <Header />
            <Content />
        </>
    );
}

export default HomePanel;