import React, { useState } from "react";
import { useContext } from "react";
import SessionContext from "../../context/SessionContext";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Image } from "react-bootstrap";
import { USER_TYPES } from "../../context/Types";


export default function SignupPanel() {
    const { signup } = useContext(SessionContext);

    const navigate = useNavigate();
    const [querying, setQuerying] = useState(false);
    function TrySignup(e) {
        e.preventDefault();
        if (querying) return;
        setQuerying(true);
        const rut = e.target.info[0].value;
        const first_name = e.target.info[1].value;
        const last_name = e.target.info[2].value;
        const email = e.target.email.value;
        const password = e.target.password.value;
        const user_type = USER_TYPES.EVALUATOR;

        signup(email, password, rut, first_name, last_name, user_type).then(result => {
            if (result) {
                // alert("Usuario creado 🥳");
                navigate("/dashboard");

            } else {
                alert("¡Ups! Hubo un error al intentar registrar un nuevo usuario ✖️");
                setQuerying(false);
            }
        });
    }

    /*return (
        <Container style={{ maxWidth: 380, textAlign: 'center' }} className='bg-white rounded shadow p-4 mt-4'>
            <Image src="logo_smart_drivr.png"></Image>
            <p>Registrar nueva cuenta 📝</p>
            <Form onSubmit={TrySignup}>
                <Form.Group controlId="info">
                    <Form.Label>Información</Form.Label>
                    <Form.Control itemID="rut" type="number" min="1" max="80000000" placeholder="R.U.T (Sin puntos ni dígito verificador)" required />
                    <Form.Control itemID="first_name" type="text" placeholder="Nombre" />
                    <Form.Control itemID="last_name" type="text" placeholder="Apellido" />
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="cuenta@email.com" required />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" placeholder="•••••••" required />
                </Form.Group>

                <Button type="submit" disabled={querying} style={{ minWidth: 120 }}>Registrar</Button>
                <br />
                <sub>Por defecto, y temporalmente, se creará un usuario tipo <b>evaluador</b> de la empresa "RST Servicios Ltda" (1)</sub>
        
            </Form>
        </Container>
    );*/
    return (
        <div className="m-auto text-center mt-5">
            <h1>Sitio no encontrado</h1>
            <p>Por favor, intente con otra URL.</p>
            <a href="/">Volver al sitio principal.</a>
            </div>
    );
}