import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import SessionState from './context/SessionState';
import reportWebVitals from './reportWebVitals';
import './App.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <SessionState>
        <App />
      </SessionState>
    </BrowserRouter>
  </>
);

