import Runs from './Runs';
import { Container, Nav, Image, Button, Alert, Col, Badge, Row, Navbar } from 'react-bootstrap';
import { useContext, useEffect, useState } from "react";
import SessionContext from "../../context/SessionContext";
import { BoxArrowInLeft, BoxArrowInRight, List, PersonCircle } from "react-bootstrap-icons";
import Sidebar from "./Sidebar";
import Faq from "./Faq";
import Admin from "./Admin";
import Axios from "axios";
import Profile from "./Profile";
import Summary from "./Summary";
import { UserTypeName } from '../../context/Types';
export default function Dashboard() {
    const { session } = useContext(SessionContext);
    const [authenticated, setAuthenticated] = useState(false)
    const [subMenu, setSubMenu] = useState("#registry");
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        setAuthenticated(session.token);
    });

    function Header() {
        return (
            <div className="d-flex justify-content-end p-2 bg-dark text-white align-items-center gap-3" >
                <div className='d-flex gap-3 align-items-center' hidden={!authenticated}>
                    <div md='auto' className="d-none d-md-block text-end">
                        <b>{session.user.first_name + " " + session.user.last_name}</b>
                        <br />
                        {session.user.company_name || ""} <Badge>{UserTypeName(session.user.type)}</Badge>
                    </div>
                    <Image style={{ height: '58px' }} roundedCircle src="https://www.w3schools.com/howto/img_avatar.png" />
                </div>
                <div className={(localStorage.getItem("machineId") ? 'bg-primary' : 'bg-danger') + ' p-2 rounded-4 text-center'}>
                    <div className='user-select-none'><small>Patente Virtual</small></div>
                    <div className='bg-light text-dark rounded-4 p-1'>{localStorage.getItem("machineId") || "No configurada"}</div>
                </div>
                <a href='javascript:void(0)' className='d-block d-md-none text-white' onClick={(e) => setShowSidebar(!showSidebar)}><List size="48" /></a>
            </div>
        );
    }

    return (
        <div className="p-0 d-flex" style={{ minHeight: '100vh' }} >
            <Sidebar setSubMenu={setSubMenu} authenticated={authenticated} show={showSidebar} />
            <div className="flex-grow-1">
                <Header />
                <div className='p-4' id="content" >
                    <Alert hidden={authenticated}>No estás autentificado o la sesión expiró. Por favor, <a href="/login">inicia sesión.</a></Alert>
                    <div hidden={!authenticated}>
                        {/* <div hidden={subMenu != "#summary"}><Summary /></div> */}
                        <div hidden={subMenu != "#registry"}><Runs /></div>
                        <div hidden={subMenu != "#profile"}><Profile /></div>
                        <div hidden={subMenu != "#admin"}><Admin /></div>
                        <div hidden={subMenu != "#faq"}><Faq /></div>
                    </div>
                </div>
            </div>
        </div >
    );
}