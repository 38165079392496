import { ArrowDownRight, ArrowUpRight, CarFrontFill, PersonFill } from "react-bootstrap-icons";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Accordion, Badge } from "react-bootstrap";

export const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            labels: {
                font: {
                    family: 'Poppins'
                }
            }
        }
    }
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function Summary() {

    const last_week_runs = {
        labels: ['7 días', '6 días', '5 días', '4 días', '3 días', 'Ayer', 'Hoy'],
        datasets: [
            {
                label: 'recorridos realizados',
                fill: true,
                data: [12, 19, 3, 5, 2, 3, 9],
                backgroundColor: "rgb(0,100,220,0.3)",
                borderColor: "rgb(0,70,250,0.6)",
                tension: 0.4,
                borderWidth: 2.5,
            },
        ],
    };
    const approval_rate = {
        labels: ['7 días', '6 días', '5 días', '4 días', '3 días', 'Ayer', 'Hoy'],
        datasets: [
            {
                label: 'tasa de aprobación %',
                data: [60, 65, 70, 70, 65, 73, 80],
                borderColor: "rgb(60,200,150)",
                borderWidth: 1,
                tension: 0.4,
                borderWidth: 2.5
            },
        ],
    };

    function DataPanel({ label, icon, value, extraValue, good = true }) {
        return (
            <div className="bg-white rounded p-3 shadow-sm user-select-none" style={{ minWidth: '170px' }}>
                {icon} <small>{label}</small>
                <br />
                <b><big>{value}</big></b> <span className={good ? "text-success" : "text-danger"}>{good ? <ArrowUpRight /> : <ArrowDownRight />}{extraValue}</span>
            </div>
        );
    }

    function RunData({ date = new Date(), driverName = "Nombre del conductor", approved = true }) {
        return (
            <>
            <div className="d-flex align-items-center user-select-none" style={{ height: '42px',marginTop:'6px',marginBottom:'6px' }}>
                <div className="w-25"><b>{date.toLocaleDateString()}</b></div>
                <div className="w-50">{driverName}</div>
                <div className="w-25 text-center">{approved ? <Badge className="bg-success">Aprobado</Badge> : <Badge className="bg-danger">Reprobado</Badge>}</div>
            </div>
            <hr style={{margin:'0'}}/>
            </>
        );
    }

    return (
        <>
            <h2>Resumen</h2>
            <hr />
            <div className="d-flex flex-wrap gap-3">
                <DataPanel label="CONDUCTORES" icon={<PersonFill />} value="5" extraValue="+16" />
                <DataPanel label="RECORRIDOS" icon={<CarFrontFill />} value="23" extraValue="+2" />
            </div>
            <div className="d-flex flex-wrap gap-3">
                <div className="bg-white rounded p-3 mt-3 shadow-sm" style={{ width: 'min(90vw,460px)' }}>
                    <Line data={last_week_runs} options={chartOptions} />
                </div>
                <div className="bg-white rounded p-3 mt-3 shadow-sm" style={{ width: 'min(90vw,460px)' }}>
                    <Line data={approval_rate} options={chartOptions} />
                </div>
            </div>
            <hr />
            <Accordion style={{ maxWidth: '600px' }}>
                <Accordion.Item>
                    <Accordion.Header><CarFrontFill style={{ marginRight: '6px' }} />Recorridos recientes</Accordion.Header>
                    <Accordion.Body className="p-3 pt-1">
                        <RunData driverName="Pablo Calderón"/>
                        <RunData driverName="Iván Poblete" approved={false}/>
                        <RunData driverName="Jaime Arancibia" approved={false}/>
                        <RunData driverName="Laura López"/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}