import { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import ShowOffText from "../show-off-text/ShowOffText";
import Progressbar from "react-js-progressbar";
import { Button, Col, Container, Form, InputGroup, Pagination, Image, Row, Spinner, ListGroup, Badge, Alert, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import SessionContext from '../../context/SessionContext';
import { Calendar, CalendarEvent, CarFrontFill, Download, ExclamationTriangleFill, GraphUpArrow, PersonCircle, Search, SearchHeart, SearchHeartFill } from 'react-bootstrap-icons';
import { CSVLink } from 'react-csv';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createRef } from 'react';
import { API_URL } from '../../context/Types';
import { ArcElement, BarElement, Chart, Title } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

const PAGINATION_SIZE = 10;

const PEN_PEDESTRIANS = 0.4;
const PEN_CAR_COLLISIONS = 0.5;
const PEN_STATICS = 0.6;
const PEN_CURBS = 0.95;
const PEN_SPEEDING = 0.8;
const PEN_RED_LIGHTS = 0.6;
const PEN_STOP_SIGNS = 0.6;
const PEN_LANE_INVASIONS = 0.75;
const PEN_HARDBRAKING = 0.95;
const PEN_ACCELERATIONS = 0.95;

const HAZARDS_AMOUNT = 10;
const IDEAL_DISTANCE = 3;
const IDEAL_SPEED = 50;

export function loadDrivers(token, setDriversFunction) {
    const queryParams = {};
    queryParams.token = token;
    Axios.get(API_URL + "/drivers", { params: queryParams }).then(response => {
        if (response.data.success) {
            const ruts = response.data.drivers.map(x => ({ id: String(x.rut), label: String(x.rut) + " - " + String(x.first_name) + " " + String(x.last_name) }));
            setDriversFunction(ruts);
        }
    });
}
const runPrototype = {
    date: new Date()
}

Chart.register(ArcElement, BarElement, Title);

export default function Runs() {
    const { session } = useContext(SessionContext);

    const typeahead = createRef();
    const [drivers, setDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState({ email: null, first_name: null, last_name: null, rut: null });
    const [loading, setLoading] = useState(false);

    const [driver_runs, setDriverRuns] = useState(new Array());
    const [selectedRun, setSelectedRun] = useState();
    const [paginationIndex, setPaginationIndex] = useState(1);

    useEffect(() => {
        loadDrivers(session.token, setDrivers);
    }, [session.token])


    function loadLocalDriverRuns() {
        const queryParams = {};
        queryParams.token = session.token;

        Axios.get(API_URL + "/run/mine", {
            params: queryParams
        }).then((response) => {
            if (response.data.success) {
                calculateRunScores(response.data);
                const email = response.data.driver_data.email;
                const first_name = response.data.driver_data.first_name;
                const last_name = response.data.driver_data.last_name;
                setSelectedDriver({ email: email, first_name: first_name, last_name: last_name, rut: null });
            } else {
                console.log("Unauthorized");
                setDriverRuns([]);
                setSelectedDriver({ email: null, first_name: null, last_name: null, rut: null });
            }
        });
    }

    function loadDriverRuns(e) {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        const value = typeahead.current.state.selected[0].id;

        const queryParams = {};
        queryParams.token = session.token;
        queryParams.rut = value;

        Axios.get(API_URL + "/run", {
            params: queryParams
        }).then((response) => {
            setLoading(false);
            if (response.data.success) {
                calculateRunScores(response.data);
                const email = response.data.user.email;
                const first_name = response.data.user.first_name;
                const last_name = response.data.user.last_name;
                setSelectedDriver({ email: email, first_name: first_name, last_name: last_name, rut: value });
            } else {
                console.log("Unauthorized");
                setDriverRuns([]);
                setSelectedDriver({ email: null, first_name: null, last_name: null, rut: null });
            }
        }).catch(err => setLoading(false));
    }

    function calculateRunScores(data) {
        for (let i = 0; i < data.runs.length; i++) {
            const run = data.runs[i];
            const date = Date.parse(run.date);

            var targetTime = new Date(date);
            var timeZoneFromDB = -0.00;
            var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
            var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
            run.date = offsetTime;



            run.hazards_score = ((run.hazards / (run.hazards_amount || HAZARDS_AMOUNT)) * 100).toFixed(2);
            run.space_score = (1 - (run.average_distance / 100)).toFixed(2); //average_distance = Porcentaje de tiempo bajo la distancia límite.
            run.speed_score = ((run.average_speed > IDEAL_SPEED) ? 100 - (run.average_speed - IDEAL_SPEED) : 100).toFixed(2); //PLACEHOLDER

            run.penalties = 1;

            run.penalties *= Math.pow(PEN_PEDESTRIANS, run.pedestrians);
            run.penalties *= Math.pow(PEN_CAR_COLLISIONS, run.car_collisions);
            run.penalties *= Math.pow(PEN_STATICS, run.statics);
            run.penalties *= Math.pow(PEN_CURBS, run.curbs);
            run.penalties *= Math.pow(PEN_SPEEDING, run.speeding);
            run.penalties *= Math.pow(PEN_RED_LIGHTS, run.red_lights);
            run.penalties *= Math.pow(PEN_STOP_SIGNS, run.stop_signs);
            run.penalties *= Math.pow(PEN_LANE_INVASIONS, run.lane_invasions);
            run.penalties *= Math.pow(PEN_HARDBRAKING, run.hardbraking);
            run.penalties = Number(run.penalties).toFixed(3);
            if (run.penalties < 0) {
                run.penalties = 0;
            }

            run.score = (((run.hazards_score / 100) * run.space_score  * (run.speed_score / 100) * run.penalties) * 100).toFixed(1);
            data.runs[i] = run;
        }
        setDriverRuns(data.runs.reverse());
    }

    function PaginationItems() {
        let active = paginationIndex;
        let items = [];
        for (let number = 1; number <= Math.floor(driver_runs.length / PAGINATION_SIZE) + (driver_runs.length % PAGINATION_SIZE != 0) ? 1 : 0; number++) {
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={() => setPaginationIndex(number)} activeLabel="">
                    {Number(number)}
                </Pagination.Item>
            );
        }
        return items;
    }

    function SearchBar() {
        return (
            <>
                <Form onSubmit={loadDriverRuns} style={{ maxWidth: 800 }}>
                    <InputGroup id='driverList'>
                        <InputGroup.Text >ID</InputGroup.Text>
                        <Typeahead
                            id='driverList'
                            options={drivers}
                            placeholder="Escribe o selecciona el ID de un conductor..."
                            emptyLabel="No se encontró ningún conductor con este ID"
                            ref={typeahead}
                        />
                    </InputGroup>
                    <Button type="submit" disabled={loading}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            hidden={!loading}
                        />
                        Cargar recorridos del conductor <Search />
                    </Button>
                    <Button hidden={driver_runs.length == 0}>
                        <CSVLink data={driver_runs} target="_blank" filename={Date.now().toString() + "_" + selectedDriver.email + ".csv"}> Exportar CSV <Download /></CSVLink>
                    </Button>
                </Form>
            </>
        );
    }

    function DriverProfile() {
        return (
            <div hidden={session.user.type == 'DRIVER'}>
                <div className='bg-white rounded p-3 shadow-sm d-flex gap-3 align-items-center mb-2' style={{ height: '100px' }}>
                    <PersonCircle size={52} />
                    <div>
                        <b>{selectedDriver.first_name + " " + selectedDriver.last_name}</b>
                        <br />
                        {selectedDriver.email}
                    </div>
                </div>
            </div>
        );
    }

    function RunList() {
        return (
            <ListGroup className='bg-white p-3 rounded shadow-sm'>
                <h4><CarFrontFill /> Recorridos</h4>
                {driver_runs.slice((paginationIndex - 1) * PAGINATION_SIZE, (paginationIndex) * PAGINATION_SIZE).map(run => <RunListItem run={run} />)}
                <Pagination className='mt-3 mb-0'>
                    <PaginationItems />
                </Pagination>
            </ListGroup>
        );
    }

    function RunListItem({ run }) {
        const score = run.score;
        const isSelected = run == selectedRun;
        return (
            <ListGroup.Item className='d-flex gap-3 justify-content-evenly user-select-none listItem' active={isSelected} onClick={(e) => { setSelectedRun(run) }} >
                <div className={isSelected ? '' : 'text-muted'}><CalendarEvent /> <small>{run.date.toLocaleDateString()}</small></div>
                {score}%
                <div>{score > 70 ? <Badge className="bg-success">Aprobado</Badge> : <Badge className="bg-danger">Reprobado</Badge>}</div>
            </ListGroup.Item >
        );
    }

    function Penalty({ name, value, amount }) {
        return (
            <tr /*hidden={amount < 1}*/>
                <td>{name}</td>
                <td>{amount}</td>
                <td>{Math.pow(value, amount).toFixed(4)}</td>
            </tr>
        );
    }

    function RunInfo() {
        if (selectedRun) {
            const score = selectedRun.score;
            const scoreData = {
                datasets: [
                    {
                        data: [score, 100 - score],
                        backgroundColor: [
                            score > 70 ? "rgb(45, 218, 123)" : "red",
                            "rgba(0,0,0,0.1)",
                        ],
                        display: true,
                        borderColor: "#D1D6DC"
                    }
                ]
            };

            const scoreOptions = {
                plugins: {
                    legend: {
                        display: true
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                rotation: -90,
                circumference: 180,
                cutout: "70%",
                maintainAspectRatio: false,
                responsive: true,

            };

            const hazardsData = {
                labels: ["Conflicto de espacio", "Visión bloqueada", "Sorpresas", "Calzada/Trayecto"],
                datasets: [
                    {
                        data: [60, 80, 95, 66],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(10,167,240)',
                    }
                ],
            };

            const hazardsOptions = {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },

                    title: {
                        font: { family: 'Poppins' },
                        display: true,
                        text: 'Rendimiento en percepción de peligros',
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            font: {
                                family: 'Poppins'
                            }
                        }
                    },
                    y: {
                        ticks: {
                            font: {
                                family: 'Poppins'
                            }
                        }
                    }
                }
            }


            return (
                <div className='bg-white rounded shadow-sm flex-grow-1 w-50 p-3 user-select-none'>
                    Este recorrido fue realizado el {selectedRun.date.toLocaleDateString("es-CL")} a las {selectedRun.date.toLocaleTimeString("es-CL")}
                    <div className='d-flex flex-wrap gap-3 mt-3  align-items-center'>
                        <div className="position-relative " style={{ width: '230px', height: '130px' }}>
                            <Doughnut data={scoreData} options={scoreOptions} />
                            <div style={{ position: 'absolute', bottom: '0', left: '50%', textAlign: 'center', transform: 'translate(-50%)', margin: '0' }}>
                                <b className='display-7 m-0'>{score + '%'}</b>
                                <p className='m-0'>Puntuación</p>
                            </div>
                        </div>
                        <div className='d-flex gap-3'>
                            <OverlayTrigger
                                key='bottom'
                                placement='auto'
                                overlay={
                                    <Tooltip id='tooltip-puntaje-peligros'>
                                        Se calcula en base a la cantidad de peligros identificados respecto al total existente.
                                    </Tooltip>
                                }>
                                <div className='text-center bg-light rounded p-3 h-25'>
                                    <div className='display-6'>{selectedRun.hazards_score}%</div>
                                    <small className='m-0'>Puntaje de percepción de peligros</small>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key='bottom'
                                placement='auto'
                                overlay={
                                    <Tooltip id='tooltip-puntaje-peligros'>
                                        Se calcula según el tiempo que el conductor estuvo sobre el límite de velocidad respecto a la duración total del recorrido.
                                    </Tooltip>
                                }>
                                <div className='text-center bg-light rounded p-3 h-25'>
                                    <div className='display-6'>{selectedRun.speed_score}%</div>
                                    <small className='m-0'>Puntaje de velocidad</small>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key='bottom'
                                placement='auto'
                                overlay={
                                    <Tooltip id='tooltip-puntaje-peligros'>
                                        Se calcula según el tiempo que el conductor estuvo bajo el límite recomendado de distancia respecto a la duración total del recorrido.
                                    </Tooltip>
                                }>
                                <div className='text-center bg-light rounded p-3 h-25'>
                                    <div className='display-6'>{selectedRun.space_score*100}%</div>
                                    <small className='m-0'>Puntaje de manejo de espacio</small>
                                </div>
                            </OverlayTrigger>
                        </div>
                        {/* <div style={{ width: '300px', height: '180px' }}>
                            <Bar data={hazardsData} options={hazardsOptions} />
                        </div> */}
                        <div>
                            <h4><ExclamationTriangleFill /> Infracciones</h4>
                            <Alert className='alert-success' hidden={selectedRun.penalties < 1}>Este conductor no tuvo ninguna penalización en este recorrido ✅</Alert>
                            <Alert className='alert-danger' hidden={selectedRun.penalties >= 1}>Este conductor tuvo una penalización de <b>{selectedRun.penalties}</b></Alert>
                            <Table bordered striped>
                                <thead className='bg-light'>
                                    <tr /*hidden={selectedRun.penalties >= 1}*/>
                                        <th>Tipo</th>
                                        <th>Cantidad</th>
                                        <OverlayTrigger
                                            key='bottom'
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id='tooltip-multiplicador'>
                                                    Indica por cuánto se va a multiplicar el puntaje general. Números menores a 1 disminuyen el puntaje.
                                                </Tooltip>
                                            }
                                        >
                                            <th>Multiplicador</th>
                                        </OverlayTrigger>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Penalty name='Colisión con la vereda' value={PEN_CURBS} amount={selectedRun.curbs} />
                                    <Penalty name='Colisión con peatones' value={PEN_PEDESTRIANS} amount={selectedRun.pedestrians} />
                                    <Penalty name='Colisión con autos' value={PEN_CAR_COLLISIONS} amount={selectedRun.car_collisions} />
                                    <Penalty name='Colisión con objetos estáticos' value={PEN_STATICS} amount={selectedRun.statics} />
                                    <Penalty name='Límite de velocidad sobrepasado' value={PEN_SPEEDING} amount={selectedRun.speeding} />
                                    <Penalty name='Luz roja no respetada' value={PEN_RED_LIGHTS} amount={selectedRun.red_lights} />
                                    <Penalty name='Disco pare no respetado' value={PEN_STOP_SIGNS} amount={selectedRun.stop_signs} />
                                    <Penalty name='Invasión de pista de sentido contrario' value={PEN_LANE_INVASIONS} amount={selectedRun.lane_invasions} />
                                    <Penalty name='Frenadas bruscas' value={PEN_HARDBRAKING} amount={selectedRun.hardbraking} />
                                </tbody>
                            </Table>
                        </div>
                        <div className='align-self-baseline'>
                            <h4><GraphUpArrow /> Estadísticas</h4>
                            <Table bordered striped>
                                <thead className='bg-light'>
                                    <tr>
                                        <th>Dato</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Velocidad promedio</td>
                                        <td>{selectedRun.average_speed} km/h</td>
                                    </tr>
                                    <tr>
                                        <OverlayTrigger
                                            key='bottom'
                                            placement='auto'
                                            overlay={
                                                <Tooltip id='tooltip-distancia-seguimiento'>
                                                    Indica la distancia calculada en tiempo de reacción para alcanzar el vehículo frente al conductor.
                                                </Tooltip>
                                            }
                                        >
                                            <td>Distancia de seguimiento promedio</td>
                                        </OverlayTrigger>
                                        <td>{selectedRun.average_distance} s</td>
                                    </tr>
                                    <tr>
                                        <td>Peligros identificados</td>
                                        <td>{selectedRun.hazards}<small>/{(selectedRun.hazards_amount || HAZARDS_AMOUNT)}</small> peligros</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Alert className='flex-grow-1 h-25'>Selecciona un recorrido para visualizar sus detalles.</Alert>
            );
        }
    }

    return (
        <div>
            <h2>Registro de recorridos 🚗</h2>
            <p>Visualiza información sobre las pruebas realizadas en el simulador.</p>
            <hr />
            {session.user.type == 'DRIVER' ? <Button onClick={loadLocalDriverRuns}>Cargar mis recorridos <Search /></Button> : <SearchBar />}
            <div className='mt-3' hidden={selectedDriver.email == null}>
                <div className="d-flex flex-wrap justify-content-start gap-2" >
                    <div style={{ maxWidth: '320px' }}>
                        <DriverProfile />
                        <RunList />
                    </div>
                    <RunInfo />
                </div>
            </div>
            <br></br>


        </div>
    );

}

