import { Button, Col, Container, Form, FormControl, FormLabel, Image, Row } from "react-bootstrap";
import { Envelope, House, Telephone, Trash } from "react-bootstrap-icons";
import SessionContext from "../../context/SessionContext";
import { useContext } from "react";

export default function Profile() {
    const { session } = useContext(SessionContext);
    return (
        <>
            <h2>Mi perfil</h2>
            Aquí podrás editar la información de tu usuario 😉
            <hr />

            {/* <h4>Foto de perfil</h4> */}
            <Form style={{ maxWidth: 640 }}>
                {/* <Container fluid >
                    <Row className="align-items-center" style={{ height: 'auto' }}>
                        <Col xs={3}><Image roundedCircle fluid src="https://www.w3schools.com/howto/img_avatar.png" /></Col>
                        <Col xs={5}>
                            <Row>
                                <Form.Control type="file" />
                            </Row>
                            <Row>
                                <Button className="bg-danger m-0"><Trash size={30} /> Eliminar</Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <sub>La foto de perfil debe ser cuadrada y tener una resolución máxima de 1920px x 1920px.</sub>
                <br /> */}
                <Col>
                    <FormLabel>Nombre/s</FormLabel>
                    <FormControl itemID="first_name" defaultValue={session.user.first_name} />
                    <FormLabel>Apellidos/s</FormLabel>
                    <FormControl itemID="last_name" defaultValue={session.user.last_name} />
                    <FormLabel>Email <Envelope /></FormLabel>
                    <FormControl type="email" disabled defaultValue={session.user.email} />
                    {/* <FormLabel>Dirección <House /></FormLabel>
                    <FormControl disabled />
                    <FormLabel>Número telefónico <Telephone /></FormLabel>
                    <FormControl disabled type="number" /> */}
                    <br />
                    <Button>Confirmar cambios</Button>
                </Col>

            </Form>

        </>
    );
}