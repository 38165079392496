import LoginPanel from './components/login/LoginPanel';
import HomePanel from './components/home/HomePanel';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import SignupPanel from './components/login/SignupPanel';
import { useContext, useEffect } from 'react';
import SessionContext from './context/SessionContext';
import { useNavigate } from 'react-router-dom';

export const PRODUCT_NAME = <b>SmartDriVR</b>;

function App() {
  const { loginWithToken } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      loginWithToken(window.localStorage.getItem("token")).then(success => {
        if (success) {
          navigate('/dashboard');
        } else {
          window.localStorage.removeItem("token", null);
          //if (window.alert("La sesión ha expirado. Por favor autentifíquese nuevamente.")) {
          //navigate('/login');
          //}
        }
      });
    }
  }, []);


  return (
    <div className="App bg-light">
      <Routes>
        <Route path="/" element={<HomePanel />} />
        <Route path="/login" element={<LoginPanel />} />
        <Route path="/signup" element={<SignupPanel />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
