import { useReducer } from "react"
import SessionContext from "./SessionContext";
import LoginReducer from './SessionReducer'
import Axios from 'axios';
import { SET_USER, SET_TOKEN, API_URL, USER_TYPES } from "./Types";

const SessionState = (props) => {
    const initialUserData = {
        user: {
            email: "",
            type: USER_TYPES.EVALUATOR,
            first_name: "",
            last_name: "",
            company_name: null
        },
        token: null,
    }
    const [userData, dispatch] = useReducer(LoginReducer, initialUserData);

    const setUser = (email, type, first_name, last_name, company_name) => {
        //Axios.get("http://127.0.0.1:3005/clear").catch(err => { });
        dispatch({ type: SET_USER, payload: { email: email, type: type, first_name: first_name, last_name: last_name || "", company_name: company_name || "" } });
    }

    const setToken = (token) => {
        window.localStorage.setItem("token", token);
        dispatch({ type: SET_TOKEN, payload: token });
    }

    const login = async (email, password) => {
        const response = await Axios.get(API_URL + "/login", {
            params: {
                email: email,
                password: password,
                machineId: localStorage.getItem("machineId")
            }
        });
        console.log(response.data);
        if (response.data.success) {
            setUser(email, response.data.user.type, response.data.user.first_name, response.data.user.last_name, response.data.user.company_name);
            setToken(response.data.token);
            return true;
        } else {
            setToken(null);
            return false;
        }
    }
    const loginWithToken = async (token) => {
        const response = await Axios.get(API_URL + "/loginWithToken", {
            params: {
                token: token,
                machineId: localStorage.getItem("machineId")
            }
        });
        console.log(response.data);
        if (response.data.success) {
            setUser(response.data.user.email, response.data.user.type, response.data.user.first_name, response.data.user.last_name, response.data.user.company_name);
            setToken(response.data.token);
            return true;
        } else {
            setToken(null);
            return false;
        }
    }

    const signup = async (email, password, rut, first_name, last_name, user_type) => {
        const response = await Axios.post(API_URL + "/signup",
            {
                email: email,
                password: password,
                rut: rut,
                first_name: first_name,
                last_name: last_name,
                user_type: user_type
            });
        if (response.data != null) {
            setUser(email, user_type, first_name, last_name);
            setToken(response.data.token);
            return (response.data);
        } else {
            setToken(null);
            console.log("error on signup")
            return (false);
        }
    }



    return (
        <SessionContext.Provider value={{
            session: {
                user: userData.user,
                token: userData.token
            },
            login, signup, setToken, loginWithToken
        }}>
            {props.children}
        </SessionContext.Provider>
    )
}

export default SessionState;