import { SET_USER, SET_TOKEN } from "./Types";

export default function (state, action) {
    const { payload, type } = action;

    switch (type) {
        case SET_USER:
            return {
                ...state,
                user: payload
            }
        case SET_TOKEN:
            return {
                ...state,
                token: payload
            }
    }
}