export const SET_USER = 'SET_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const API_URL = "https://smart-drivr-server.onrender.com";
export const USER_TYPES = {
    EVALUATOR: "EVALUATOR",
    DRIVER: "DRIVER",
    ADMIN: "ADMIN"
}

export function UserTypeName(type) {
    switch (type) {
        case USER_TYPES.EVALUATOR:
            return "MÁNAGER";
        case USER_TYPES.DRIVER:
            return "CONDUCTOR"
        case USER_TYPES.ADMIN:
            return "ADMINISTRADOR";
        default:
            return "USUARIO"
    }
}
// export const API_URL = "http://localhost:3001";