import './style.css'

function ShowOffText({ label, subtitle }) {
    return (
        <div className='show-off-text'>
            <span id='label'>{label}</span>
            <br></br>
            <span id='subtitle'>{subtitle}</span>
        </div>
    );
}

export default ShowOffText;