import React, { useState } from "react";
import { Button, Form, Spinner, Alert } from "react-bootstrap";
import { API_URL } from "../context/Types";
import axios from "axios";



function MachineIdForm() {

    const [loading, setLoading] = useState(false);

    function handleSubmitMachine(e) {
        e.preventDefault();

        if (loading) return;
        setLoading(true);
        const machineId = String(e.target.machine.value).toUpperCase();
        localStorage.setItem("machineId", machineId);
        validateMachine();
    }

    function validateMachine() {
        //eliminar si es antigua, mostrar inicio de sesion si es válida
        axios.get(API_URL + "/validateMachine", {
            params: {
                machineId: localStorage.getItem("machineId")
            }
        }).then(result => {
            if (!result.data) {
                localStorage.removeItem("machineId");
                alert("La patente que has ingresado no existe. Recuerda que debe ser equivalente a la mostrada en el simulador.")
            }
            setLoading(false);

        }).catch(err => {
            console.log("Error consultando por la patente.");
            setLoading(false);

        });
    }
    function handleChange(event) {
        event.target.value = event.target.value.toUpperCase();
    }

    return (
        <div>
            <Alert>Este navegador no está asociado a ningún simulador. Por favor, ingrese la patente virtual a continuación:</Alert>
            <Form onSubmit={handleSubmitMachine}>
                <Form.Group controlId="machine">
                    <Form.Label>Patente virtual</Form.Label>
                    <Form.Control type="string" required placeholder="VFSF93" maxLength="6" size="8" onChange={handleChange} />
                </Form.Group>
                <Button type="submit" disabled={loading} style={{ minWidth: 120 }}>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        hidden={!loading}
                    />
                    {loading ? " Cargando..." : "Establecer"}
                </Button>
            </Form>
        </div>
    )
}

export default MachineIdForm;