import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

export default function Faq() {
    return (
        <Accordion id="faq2" defaultActiveKey='0' style={{ maxWidth: '960px' }}>
            <AccordionItem eventKey="0">
                {/* <Accordion.Header>Daemon y simulador</Accordion.Header>
                <Accordion.Body>
                    <p>Para poder usar el simulador correctamente, es necesario que descarges el <a href="/daemon.exe">daemon aquí.</a></p>
                    <p>Este programa permite iniciar sesión para que tu registro quede guardado con tu perfil.</p>
                    Instrucciones:
                    <li>Abra el archivo daemon.exe.</li>
                    <li>Abra el simulador.</li>
                    <li>En la consola del daemon, escriba <i>login [tu correo] [tu contraseña]</i></li>
                    <li>Compruebe que su nombre aparezca en el panel bajo el velocímetro del auto en el simulador.</li>
                    <li>Complete la ruta y sus datos serán enviados al servidor.</li>
                </Accordion.Body> */}
            </AccordionItem>
        </Accordion>

    );
}