import React, { useEffect, useState } from "react";
import { useContext } from "react";
import SessionContext from "../../context/SessionContext";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form, Spinner, Image, Alert } from "react-bootstrap";
import Axios from 'axios';
import { API_URL } from "../../context/Types";

export default function LoginPanel() {
    const { login } = useContext(SessionContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("machineId")) {
            validateMachine();
        }
    }, []);

    function validateMachine() {
        //eliminar si es antigua, mostrar inicio de sesion si es válida
        Axios.get(API_URL + "/validateMachine", {
            params: {
                machineId: localStorage.getItem("machineId")
            }
        }).then(result => {
            if (!result.data) {
                localStorage.removeItem("machineId");
            }
            setLoading(false);

        }).catch(err => {
            console.log("Error consultando por la patente.");
            setLoading(false);

        });
    }

    function handleSubmitLogin(e) {
        e.preventDefault();
        TryLogin(e.target.email.value, e.target.password.value);
    }

 

    function TryLogin(email, password) {
        if (loading) return;

        setLoading(true);
        login(email, password).then(result => {
            if (result) {
                // alert("Sesion iniciada correctamente 🥳");
                navigate("/dashboard");

            } else {
                alert("¡Ups! El email o la contraseña no son correctos ✖️");
                setLoading(false);
            }
        });

    }

    function LoginForm() {
        return (
            <Form onSubmit={handleSubmitLogin}>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="cuenta@email.com" />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" placeholder="•••••••" />
                </Form.Group>
                <Button type="submit" disabled={loading} style={{ minWidth: 120 }}>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        hidden={!loading}
                    />
                    {loading ? " Cargando..." : "Entrar"}
                </Button>
            </Form>
        );
    }



    return (
        <Container style={{ maxWidth: 380, textAlign: 'center' }} className='bg-white rounded shadow p-4 mt-4'>
            <Image src="logo_smart_drivr.png"></Image>
            <p className="mt-2">¡Hola de nuevo!</p>
            {<LoginForm />}
            {/* {localStorage.getItem("machineId") && <LoginForm />} */}
            {/* {!localStorage.getItem("machineId") && <MachineForm />} */}
        </Container>
    );

}