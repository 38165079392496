import { Axios } from "axios";
import React from "react";
import { useState, useContext } from "react";
import { Nav, Navbar, Image, Button, Modal } from "react-bootstrap";
import { BoxArrowInLeft, BoxArrowInRight, CarFrontFill, CCircle, Gear, GraphUp, ListCheck, Pen, PersonCircle, QuestionCircle } from "react-bootstrap-icons";
import SessionContext from "../../context/SessionContext";
import { USER_TYPES } from "../../context/Types";

function Sidebar({ setSubMenu, authenticated, show }) {
    const { session } = useContext(SessionContext);

    const [selected, setSelected] = useState("#registry");

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const logout = () => {
        window.localStorage.removeItem("token");
        //Axios.get("http://127.0.0.1:3005/clear").catch(err => { });
    }

    function LogOutModal() {
        return (
            <>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cerrar Sesión</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Quieres cerrar tu sesión activa?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button href="/login" variant="primary" onClick={logout}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return (
        <Nav id="sidebar" className={"d-md-block bg-primary sidebar" + (show ? " d-block" : " d-none")} activeKey="/" onSelect={(selectedKey, e) => {
            setSubMenu(selectedKey);
            setSelected(selectedKey);
        }}>
            <Navbar.Brand>
                <Nav.Link href="/" onClick={() => { window.localStorage.removeItem('token') }}><Image src="logo_smart_drivr_light.png" fluid /></Nav.Link>
            </Navbar.Brand>
            <br />
            {/* <Nav.Link id={selected == "#summary" ? "active" : ""} href="#summary"><GraphUp /> Resumen</Nav.Link> */}
            <Nav.Link id={selected == "#registry" ? "active" : ""} href="#registry"><CarFrontFill /> Registro</Nav.Link>
            {/* <Nav.Link id={selected == "#profile" ? "active" : ""} href="#profile"><PersonCircle /> Perfil</Nav.Link> */}
            <Nav.Link id={selected == "#admin" ? "active" : ""} href="#admin" hidden={session.user.type == USER_TYPES.DRIVER}> <Gear /> Administración</Nav.Link>
            { <Nav.Link id={selected == "#faq" ? "active" : ""} href="#faq"><QuestionCircle /> Recursos y preguntas fecuentes</Nav.Link>}
            <Nav.Link onClick={handleShow}>
                <BoxArrowInLeft hidden={!authenticated} />
                <BoxArrowInRight hidden={authenticated} />
                {authenticated ? ' Cerrar Sesión' : ' Iniciar Sesión'}
            </Nav.Link>
            <LogOutModal />
            <div className="position-absolute bottom-0 text-white mb-3 user-select-none">Sciremotion <CCircle /> {new Date().getFullYear()}</div>
        </Nav >
    );
}
export default Sidebar